import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  imports: [CommonModule],
  standalone: true,
  selector: 'register-success',
  templateUrl: './register-success.component.html',
})
export class RegisterSuccessComponent implements OnInit {
  domain: string = '';
  constructor() {
    this.domain = window.location.hostname.toUpperCase();
  }

  ngOnInit(): void {}
}
